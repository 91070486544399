import { Table, useTable, Typography,
  Image, Button, Icons, Row, Col, Form, Input, } from "@pankod/refine-antd";
import { CrudFilters, useDataProvider } from "@pankod/refine-core";
import { useState } from "react";
const { Text } = Typography;


export const SelectPhotoProducts: React.FC<{photoId: string, onUpdate: any, productIds: any}> = ({ photoId, onUpdate, productIds }) => {
  // const [productId, setProductId] = useState("");

  console.log('selected photo products', productIds);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(productIds || []);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
    onUpdate(newSelectedRowKeys);
  };

  const { tableProps, searchFormProps, setFilters } = useTable<IShopifyProduct>({
    resource: `storefronts/products`,
    dataProviderName: 'sf',
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { title } = params;
      filters.push({
        field: 'title',
        operator: "contains",
        value: title,
      });
      return filters;
    },
  });
  tableProps.rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: 'odd',
        text: 'Select Odd Row',
        onSelect: (changableRowKeys: any) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_: any, index: number) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: 'even',
        text: 'Select Even Row',
        onSelect: (changableRowKeys: any) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_: any, index: number) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };
//   const sfApiUrl = useApiUrl('sf');
const dataProvider = useDataProvider();
// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function updateProductPhotos (productIds: any, sfId: any, photoId: any) {
  const sfDp = dataProvider('sf');
  const vDp = dataProvider('default');
  console.log('product id', productIds, 'photoId', photoId);
  if(sfDp) {
    await Promise.all([
      sfDp.create({
        resource: `storefronts/products/update-photos`,
        variables: {
          newPhotoId: photoId,
          productIds,
        },
      }),
      vDp.update({
        id: photoId,
        resource: `photos`,
        variables: {
          productIds,
        },
      })
    ]);
  }
};

  return (
    <Row gutter={[16, 16]}>
            <Col xs={24}>
                <Form layout="inline" {...searchFormProps} onSubmitCapture={(e)=>{ e.preventDefault() }}>
                    <Form.Item name="title">
                        <Input
                            placeholder="Title or Name"
                            prefix={<Icons.SearchOutlined />}
                            onChange={(e) => {
                              const filters: CrudFilters = [];
                              filters.push({
                                field: 'title',
                                operator: 'contains',
                                value: e.target.value,
                              })
                              setFilters(filters) 
                            }}
                        />
                    </Form.Item>
                </Form>
            </Col>
            <Col xs={24}>
    
          <Table {...tableProps} rowKey="id">
              <Table.Column dataIndex="featuredImage" render={
                (value, record: any) => <Image src={record?.platformProduct.featuredImage.url} height={96} width={96} />
              } title="Image" />
              <Table.Column dataIndex="title" render={
                (value, record: any) => <Text>{record?.platformProduct.title}</Text>
              } title="Title" />
              <Table.Column dataIndex="onlineStorePreviewUrl"
              render={(value, record: any) => (
                <>
                <Button href={record?.platformProduct.onlineStorePreviewUrl} target="_blank" title="Preview" style={{ marginLeft: '10px'}}><Icons.EyeOutlined /></Button></>
              )} title="Actions" />
          </Table>
      </Col>
        </Row>
  );
};

// interface IProduct {
//   id: string;
//   title: string;
//   featuredImage: object;
//   priceRangeV2: object;
//   onlineStorePreviewUrl: object;
//   options: object;
//   totalVariants: number;
//   hasOnlyDefaultVariant: boolean;
//   hasOutOfStockVariants: boolean;
//   images: object;
//   status: string;
// }

interface IShopifyProduct {
  id: string,
  platform: string;
  platformId: string;
  platformProduct: object;
}