import { Table, useTable, Typography,
  Image, Button, Icons, Row, Col, Form, Input, } from "@pankod/refine-antd";
import { CrudFilters, useDataProvider } from "@pankod/refine-core";
import { useState } from "react";
const { Text } = Typography;


export const SelectVideoProducts: React.FC<{videoId: string, onUpdate: any, productIds: any, platformIds: boolean}> = ({ videoId, onUpdate, productIds, platformIds }) => {
  // const [productId, setProductId] = useState("");

  console.log('selected video products', productIds);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(productIds || []);

  const { tableProps, searchFormProps, setFilters, tableQueryResult } = useTable<IShopifyProduct>({
    resource: `storefronts/products`,
    dataProviderName: 'sf',
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { title } = params;
      filters.push({
        field: 'title',
        operator: "contains",
        value: title,
      });
      return filters;
    },
  });

  searchFormProps.onFinish = () => {
    return false;
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
    
    if(platformIds) {
      let newSelectedPlatformIds: any = tableQueryResult.data?.data;
      newSelectedPlatformIds = newSelectedPlatformIds.filter((v: any) => (newSelectedRowKeys.includes(v.id)))
      newSelectedPlatformIds = newSelectedPlatformIds.map((v: any) => v.platformId);
      onUpdate(newSelectedRowKeys, newSelectedPlatformIds);
    } else {
      onUpdate(newSelectedRowKeys);
    }
  };

  tableProps.rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: 'odd',
        text: 'Select Odd Row',
        onSelect: (changableRowKeys: any) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_: any, index: number) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: 'even',
        text: 'Select Even Row',
        onSelect: (changableRowKeys: any) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_: any, index: number) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };
//   const sfApiUrl = useApiUrl('sf');
const dataProvider = useDataProvider();
// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function updateProductVideos (productIds: any, sfId: any, videoId: any) {
  const sfDp = dataProvider('sf');
  const vDp = dataProvider('default');
  console.log('product id', productIds, 'videoId', videoId);
  if(sfDp) {
    await Promise.all([
      sfDp.create({
        resource: `storefronts/products/update-videos`,
        variables: {
          newVideoId: videoId,
          productIds,
        },
      }),
      vDp.update({
        id: videoId,
        resource: `videos`,
        variables: {
          productIds,
        },
      })
    ]);
  }
};

const getFeaturedImage = (record: any) => {
  if (record.platform === 'shopify' && record.platformProduct.featuredImage) {
    return record.platformProduct.featuredImage?.url;
  }
  return record.platformProduct.images?.[0]?.src || '';
}

  return (
    <Row gutter={[16, 16]}>
            <Col xs={24}>
                <Form layout="inline" {...searchFormProps} onSubmitCapture={(e)=>{ e.preventDefault() }}>
                    <Form.Item name="title">
                        <Input
                            placeholder="Title or Name"
                            prefix={<Icons.SearchOutlined />}
                            onChange={(e) => {
                              const filters: CrudFilters = [];
                              filters.push({
                                field: 'title',
                                operator: 'contains',
                                value: e.target.value,
                              })
                              setFilters(filters) 
                            }}
                        />
                    </Form.Item>
                </Form>
            </Col>
            <Col xs={24}>
    
          <Table {...tableProps} rowKey="id">
              <Table.Column dataIndex="id" render={
                (value, record: any) => <Image src={getFeaturedImage(record)} height={96} width={96} />
              } title="Image" />
              <Table.Column dataIndex="id" render={
                (value, record: any) => <Text>{record?.platformProduct?.title || record?.platformProduct?.name}</Text>
              } title="Title" />
              <Table.Column dataIndex="id"
              render={(value, record: any) => (
                <>
                <Button href={record?.platformProduct?.onlineStorePreviewUrl || record?.platformProduct?.permalink} target="_blank" title="Preview" style={{ marginLeft: '10px'}}><Icons.EyeOutlined /></Button></>
              )} title="Actions" />
          </Table>

      </Col>
        </Row>
  );
};

// interface IProduct {
//   id: string;
//   title: string;
//   featuredImage: object;
//   priceRangeV2: object;
//   onlineStorePreviewUrl: object;
//   options: object;
//   totalVariants: number;
//   hasOnlyDefaultVariant: boolean;
//   hasOutOfStockVariants: boolean;
//   images: object;
//   status: string;
// }

interface IShopifyProduct {
  id: string,
  platform: string;
  platformId: string;
  platformProduct: object;
}