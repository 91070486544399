import {
  useTranslate,
} from "@pankod/refine-core";
import cubejs from '@cubejs-client/core';
import { QueryRenderer } from "@cubejs-client/react";
import { Col, Card, Spin, Row, Statistic,  useSimpleList, AntdList } from "@pankod/refine-antd";
// import { Chart, Axis, Tooltip, Geom, Coord, Legend, Label } from "bizcharts";
import moment from "moment";
import { IChannel } from "interfaces";
import { PlaylistPerformance } from ".";
// import numeral from "numeral";

// const numberFormatter = (item: any) => numeral(item).format("0,0");

const dateFormatter = (item: any) => moment(item).format("MMM YY");

const colors = ["#7DB3FF", "#49457B", "#FF7C78"];
let apiTokenPromise;

const renderChart = (Component: any, options = {}) => ({ resultSet, error } : any) =>
  (resultSet && <Component resultSet={resultSet} {...options} />) ||
  (error && error.toString()) || <div style={{ textAlign: "center", paddingTop: 30 }}><Spin /> </div>;

const DashboardItem = ({ children, title, size, height }: any) => (
  <Col span={24} lg={size}>
    <Card
      title={title}
      style={{
        marginBottom: "24px",
        marginRight: "12px",
      }}
    >
      <div style={{height: height}}>
        {children}
      </div>
    </Card>
  </Col>
);

DashboardItem.defaultProps = {
  size: 12
};

const numberRender = ({ resultSet }: any) => (
  <Row
    justify="center"
    align="middle"
    style={{
      height: "100%"
    }}
  >
    <Col>
      {resultSet.seriesNames().map((s: any) => (
        <Statistic value={resultSet.totalRow()[s.key]} />
      ))}
    </Col>
  </Row>
);


export const Performance: React.FC<any> = ({
  dateRange,
}) => {
  const t = useTranslate();
  const apiKey: any = process.env.REACT_APP_CUBEJS_API_KEY;
  const apiUrl: any = process.env.REACT_APP_CUBEJS_API_URL;
  const cubejsApi = cubejs(
    apiKey,
    { apiUrl }
  );

  let env: string = process.env.REACT_APP_ENV || 'prod';
  if(env === 'prod' || env === '') {
    env = 'prod';
  }

  const { listProps } = useSimpleList<
  IChannel
  >({
      hasPagination: false,
      resource: 'channels',
  });

  const defaultFilters: any = [
    {
      member: `Events.env`,
      operator: `equals`,
      values: [env]
    },
    {
      member: `Events.appId`,
      operator: `equals`,
      values: ['allstudio']
    },
    {
      member: `Events.accountId`,
      operator: `equals`,
      values: [localStorage.getItem("accountId") || '']
    },
  ];

  
  return (
      <>
         <Row>
            <DashboardItem title="Active Plays (> 3s)" size={6} height={80}>
              <QueryRenderer
                query={{
                  measures: [`ASActiveViewsMv.count`],
                  timeDimensions: [
                    {
                      dimension: `ASActiveViewsMv.day`,
                      dateRange,
                    }
                  ],
                  filters: [
                    {
                      member: `ASActiveViewsMv.env`,
                      operator: `equals`,
                      values: [env]
                    },
                    {
                      member: `ASActiveViewsMv.accountId`,
                      operator: `equals`,
                      values: [localStorage.getItem("accountId") || '']
                    },
                  ]
                }}
                cubejsApi={cubejsApi}
                render={renderChart(numberRender)}
              />
            </DashboardItem>

            <DashboardItem title="Total Visitors" size={6} height={80}>
              <QueryRenderer
                query={{
                  measures: [`ASWatchTimeMV.activeSessions`],
                  timeDimensions: [
                    {
                      dimension: `ASWatchTimeMV.day`,
                      dateRange,
                    }
                  ],
                  filters: [
                    {
                      member: `ASWatchTimeMV.env`,
                      operator: `equals`,
                      values: [env]
                    },
                    {
                      member: `ASWatchTimeMV.accountId`,
                      operator: `equals`,
                      values: [localStorage.getItem("accountId") || '']
                    },
                  ]
                }}
                cubejsApi={cubejsApi}
                render={renderChart(numberRender)}
              />
            </DashboardItem>

            <DashboardItem title="Watch Time (seconds)" size={6} height={80}>
              <QueryRenderer
                query={{
                  measures: [`ASWatchTimeMV.totalWatchTime`],
                  timeDimensions: [
                    {
                      dimension: `ASWatchTimeMV.day`,
                      dateRange,
                    }
                  ],
                  filters: [
                    {
                      member: `ASWatchTimeMV.env`,
                      operator: `equals`,
                      values: [env]
                    },
                    {
                      member: `ASWatchTimeMV.accountId`,
                      operator: `equals`,
                      values: [localStorage.getItem("accountId") || '']
                    },
                  ]
                }}
                cubejsApi={cubejsApi}
                render={renderChart(numberRender)}
              />
            </DashboardItem>

            <DashboardItem title="Watch Time per Visitor" size={6} height={80}>
              <QueryRenderer
                query={{
                  measures: [`ASWatchTimeMV.watchTimePerSession`],
                  timeDimensions: [
                    {
                      dimension: `ASWatchTimeMV.day`,
                      dateRange,
                    }
                  ],
                  filters: [
                    {
                      member: `ASWatchTimeMV.env`,
                      operator: `equals`,
                      values: [env]
                    },
                    {
                      member: `ASWatchTimeMV.accountId`,
                      operator: `equals`,
                      values: [localStorage.getItem("accountId") || '']
                    },
                  ]
                }}
                cubejsApi={cubejsApi}
                render={renderChart(numberRender)}
              />
            </DashboardItem>
     
            <DashboardItem title="Video Clicks (Fullscreen)" size={6} height={80}>
              <QueryRenderer
                query={{
                  measures: [`ASMetricsMv.count`],
                  timeDimensions: [
                    {
                      dimension: `ASMetricsMv.day`,
                      dateRange,
                    }
                  ],
                  filters: [
                    {
                      member: `ASMetricsMv.event_type`,
                      operator: `equals`,
                      values: ['full_screen']
                    },
                    {
                      member: `ASMetricsMv.env`,
                      operator: `equals`,
                      values: [env]
                    },
                    {
                      member: `ASMetricsMv.accountId`,
                      operator: `equals`,
                      values: [localStorage.getItem("accountId") || '']
                    },
                  ]
                }}
                cubejsApi={cubejsApi}
                render={renderChart(numberRender)}
              />
            </DashboardItem>
    
            <DashboardItem title="Product/CTA Clicks" size={6} height={80}>
              <QueryRenderer
                query={{
                  measures: [`ASMetricsMv.count`],
                  timeDimensions: [
                    {
                      dimension: `ASMetricsMv.day`,
                      dateRange,
                    }
                  ],
                  filters: [
                    {
                      member: `ASMetricsMv.event_type`,
                      operator: `equals`,
                      values: ['cta_click', 'open_product_details_overlay']
                    },
                    {
                      member: `ASMetricsMv.env`,
                      operator: `equals`,
                      values: [env]
                    },
                    {
                      member: `ASMetricsMv.accountId`,
                      operator: `equals`,
                      values: [localStorage.getItem("accountId") || '']
                    },
                  ]
                }}
                cubejsApi={cubejsApi}
                render={renderChart(numberRender)}
              />
            </DashboardItem>

            <DashboardItem title="Add to Carts" size={6} height={80}>
              <QueryRenderer
                query={{
                  measures: [`ASMetricsMv.count`],
                  timeDimensions: [
                    {
                      dimension: `ASMetricsMv.day`,
                      dateRange,
                    }
                  ],
                  filters: [
                    {
                      member: `ASMetricsMv.event_type`,
                      operator: `equals`,
                      values: ['cta_click_success', 'addtocart_click']
                    },
                    {
                      member: `ASMetricsMv.env`,
                      operator: `equals`,
                      values: [env]
                    },
                    {
                      member: `ASMetricsMv.accountId`,
                      operator: `equals`,
                      values: [localStorage.getItem("accountId") || '']
                    },
                  ]
                }}
                cubejsApi={cubejsApi}
                render={renderChart(numberRender)}
              />
            </DashboardItem>

            <DashboardItem title="View Product Clicks" size={6} height={80}>
              <QueryRenderer
                query={{
                  measures: [`ASMetricsMv.count`],
                  timeDimensions: [
                    {
                      dimension: `ASMetricsMv.day`,
                      dateRange,
                    }
                  ],
                  filters: [
                    {
                      member: `ASMetricsMv.event_type`,
                      operator: `equals`,
                      values: ['view_product_details']
                    },
                    {
                      member: `ASMetricsMv.env`,
                      operator: `equals`,
                      values: [env]
                    },
                    {
                      member: `ASMetricsMv.accountId`,
                      operator: `equals`,
                      values: [localStorage.getItem("accountId") || '']
                    },
                  ]
                }}
                cubejsApi={cubejsApi}
                render={renderChart(numberRender)}
              />
            </DashboardItem>
          </Row>

          <Row>
            {/* <DashboardItem title="Playlist Performance" size={24} height={320}>

<AntdList
                        
                        style={{
                            height: "100%",
                            overflow: "auto",
                            paddingRight: "8px",
                        }}
                        {...listProps}
                        renderItem={(item) => (
                          //   <PlaylistItem item={item} editShow={editShow} />

                            <PlaylistPerformance channelId={item.id} dateRange={dateRange} />
                        )}
                    />
              
            </DashboardItem> */}
          </Row>
      </>
  );
};