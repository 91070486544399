import React from "react";
import { Refine, Authenticated, AuthProvider, ResourceProps } from "@pankod/refine-core";
import {
  notificationProvider,
  Layout,
  ReadyPage,
  ErrorComponent,
  Icons,
  // useSimpleList,
} from "@pankod/refine-antd";
import routerProvider from "@pankod/refine-react-router-v6";
import dataProvider from "@pankod/refine-simple-rest";
import axios, { AxiosRequestConfig } from "axios";
import { useTranslation } from "react-i18next";

import "@pankod/refine-antd/dist/styles.min.css";
import { AccountProvider } from "context/account";
// import {AsPlayer} from "@allcommercehq/allstudio";

// Import the functions you need from the SDKs you need
// import { auth } from "firebaseui";
// import { initializeApp } from 'firebase/app';
// import { getAuth, onAuthStateChanged, signOut,
// GoogleAuthProvider, EmailAuthProvider, PhoneAuthProvider } from "firebase/auth";
// import { getAnalytics } from "firebase/analytics";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { CustomSider } from "components/sider";
// import { VideoList, VideoShow, VideoEdit, VideoCreate } from "./pages/videos";
import { AnalyticsList } from "./pages/analytics";
import { ChannelCreate, ChannelShow, ChannelEdit } from "./pages/channels";
import { StorefrontCreate, StorefrontShow, StorefrontEdit } from "./pages/storefronts";
import { AccountCreate } from "./pages/accounts";
import { Login } from "pages/login";
// import { IStorefront } from "interfaces";
// import 'firebaseui/dist/firebaseui.css'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyCrRr3nipfWhNaxv-PS_0ZAI8vOes7JbAw",
//   authDomain: "dev-allcommerce.firebaseapp.com",
//   projectId: "dev-allcommerce",
//   storageBucket: "dev-allcommerce.appspot.com",
//   messagingSenderId: "241544257531",
//   appId: "1:241544257531:web:7963dd31f540a1e843b55e",
//   measurementId: "G-6S5D1Z33F6"
// };

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// const fauth = getAuth(app);

const axiosInstance = axios.create();
const appAxiosInstance = axios.create();
const sfAxiosInstance = axios.create({
  withCredentials: true,
});

// console.log(process.env);

const AUTH_API_URL: any = process.env.REACT_APP_AUTH_API_URL;
const APP_API_URL: any = process.env.REACT_APP_APP_API_URL;
const SF_API_URL: any = process.env.REACT_APP_SF_API_URL;


axiosInstance.interceptors.request.use((request: AxiosRequestConfig) => {
    const token = localStorage.getItem("token");
    let authorization: any = `Bearer ${token}`;
    if(!token || token === '') {
      authorization = process.env.REACT_APP_APP_KEY;
    }
    if (request.headers) {
        request.headers["Authorization"] = authorization;
    } else {
        request.headers = {
            Authorization: authorization,
        };
    }

    return request;
});

appAxiosInstance.interceptors.request.use((request: AxiosRequestConfig) => {
  const token = localStorage.getItem("token");
  const accountId = localStorage.getItem("accountId");
  let authorization: any = `Bearer ${token}`;
  if(!token || token === '') {
    authorization = process.env.REACT_APP_APP_KEY;
  }
  if (request.headers) {
      request.headers["Authorization"] = authorization;
  } else {
      request.headers = {
        Authorization: authorization,
      };
  }
  request.headers['account_id'] = accountId || "";
  return request;
});

sfAxiosInstance.interceptors.request.use((request: AxiosRequestConfig) => {
  const token = localStorage.getItem("token");
  const accountId = localStorage.getItem("accountId");
  let authorization: any = `Bearer ${token}`;
  if(!token || token === '') {
    authorization = process.env.REACT_APP_APP_KEY;
  }
  if (request.headers) {
      request.headers["Authorization"] = authorization;
  } else {
      request.headers = {
        Authorization: authorization,
      };
  }
  request.headers['account_id'] = accountId || "";
  return request;
});

// appAxiosInstance.interceptors.response.use((response: AxiosResponse) => {
//   console.log(response);
//   return response;
// });

// const AuthenticatedChannelCreate = () => {
//   return (
//       <Authenticated>
//           <ChannelCreate />
//       </Authenticated>
//   );
// };

const AuthenticatedAccountCreate = () => {
  return (
      <Authenticated>
          <AccountCreate />
      </Authenticated>
  );
};


function App() {
  const authProvider: AuthProvider = {
    login: ({ message, messageCode, data }) => {
      if (messageCode === 'OTP_VERIFIED') {
          localStorage.setItem("user", JSON.stringify(data.profile));
          localStorage.setItem("token", data.accessToken);
          return Promise.resolve();
      }

      return Promise.reject({ message });
    },
    logout: () => {
      const token = localStorage.getItem("token");

      if (token && typeof window !== "undefined") {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          axios.defaults.headers.common = {};
          window.location.href = "/";
          // window.google?.accounts.id.revoke(token, () => {
          //     return Promise.resolve();
          // });
      }

        return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () =>
        localStorage.getItem("token")
            ? Promise.resolve()
            : Promise.reject(),
    getPermissions: () => Promise.resolve(["admin"]),
    getUserIdentity: async () => {
      const user = localStorage.getItem("user");
      if (user) {
          return Promise.resolve(JSON.parse(user));
      }
      return false;
    },
  };


  // const { listProps } = useSimpleList<IStorefront>({
  //   resource: 'storefronts',
  // });
  // const stores = listProps?.dataSource?.map((sf) => {
  //   return {
  //     parentName: 'Stores',
  //     name: sf.name,
  //     label: sf.name,
  //     options: { label: "custom", route: `stores/${sf.id}` },
  //   }
  // }) ?? [];
  // const stores:ResourceProps[] = [{
  //   parentName: 'stores',
  //   name: 'test',
  // }];

  const { t, i18n } = useTranslation();

  const i18nProvider = {
      translate: (key: string, params: object) => t(key, params),
      changeLocale: (lang: string) => i18n.changeLanguage(lang),
      getLocale: () => i18n.language,
  };

  // const locale = i18nProvider.getLocale();


  return (
    <AccountProvider>
      <Refine
        authProvider={authProvider}
        LoginPage={Login}
        options={{ liveMode: "auto" }}
        Sider={CustomSider}
        i18nProvider={i18nProvider}
        notificationProvider={notificationProvider}
        Layout={Layout}
        ReadyPage={ReadyPage}
        catchAll={<ErrorComponent />}
        routerProvider={{
          ...routerProvider,
          routes: [
            {
              element: <AuthenticatedAccountCreate />,
              path: '/accounts/create',
              layout: false
            },
          ]
        }}
        dataProvider={{
          default: dataProvider(APP_API_URL, appAxiosInstance),
          auth: dataProvider(AUTH_API_URL, axiosInstance),
          sf: dataProvider(SF_API_URL, sfAxiosInstance),
        }}
        resources={[
          {
            name: "analytics",
            list: AnalyticsList,
            icon: <Icons.RiseOutlined />,
          },
          {
            name: "channels",
            // list: ChannelList,
            show: ChannelShow,
            edit: ChannelEdit,
            create: ChannelCreate,
            list: ChannelCreate,
            canDelete: false,
            icon: <Icons.VideoCameraOutlined />,
          },
          // {
          //   name: "videos",
          //   list: VideoList,
          //   show: VideoShow,
          //   edit: VideoEdit,
          //   create: VideoCreate,
          //   canDelete: true,
          // },
          {
            name: 'stores',
            create: StorefrontCreate,
            list: StorefrontCreate,
            canDelete: false,
            icon: <Icons.ShopOutlined />,
          },
          {
            name: 'storefronts',
            show: StorefrontShow,
            edit: StorefrontEdit,
            canDelete: false,
            icon: <Icons.ShopOutlined />,
          },
        ]}
      />
    </AccountProvider>
  );
}

export default App;

export {
  APP_API_URL,
  AUTH_API_URL,
  SF_API_URL,
};
