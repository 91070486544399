import { useTranslate } from "@pankod/refine-core";
import {
  Edit,
  Form,
  Input,
  // Select,
  useForm,
  Radio,
  // useSelect,
} from "@pankod/refine-antd";

import { Colorpicker } from 'antd-colorpicker';

import { IChannel } from "interfaces";

export const ChannelEdit = () => {

  const t = useTranslate();

  const { formProps, saveButtonProps } = useForm<IChannel>({
    resource: 'channels',
    redirect: 'show',
    action: "edit",
  });
  
  const { form } = formProps;

  if(!form?.getFieldValue('primaryColor')) {
    form?.setFieldsValue({
        primaryColor: {
          rgb: {
            r: 70,
            g: 129,
            b: 244,
            a: 1,
          },
          hex: '#4681f4',
          source: 'hex'
        },
    });
  }  

  if(!form?.getFieldValue('textColor')) {
    form?.setFieldsValue({
        textColor: {
          rgb: {
            r: 255,
            g: 255,
            b: 255,
            a: 1,
          },
          hex: '#ffffff',
          source: 'hex'

        },
    });
  }
  
  if(!form?.getFieldValue('ctaButtonSize')) {
    form?.setFieldsValue({
        ctaButtonSize: "large",
    });
  }

  // const { selectProps: categorySelectProps } = useSelect<IPost>({
  //     resource: "categories",
  // });

  return (
      <Edit title="Edit Channel" 
      pageHeaderProps={{ extra: null }} 
      breadcrumb={false}
       saveButtonProps={saveButtonProps}>
          <Form {...formProps} layout="vertical">
              <Form.Item
                  label="Channel name"
                  name="title"
                  rules={[
                      {
                          required: true,
                      },
                      {
                          min: 2
                      },
                  ]}
              >
                  <Input />
              </Form.Item>

              <Form.Item
                  label="Primary Color (Button)"
                  name="primaryColor"
                  rules={[
                      {
                          required: true,
                      },
                  ]}
              >
                  <Colorpicker popup picker="ChromePicker" />
              </Form.Item>

              <Form.Item
                  label="Text Color (Button Text)"
                  name="textColor"
                  rules={[
                      {
                          required: true,
                      },
                  ]}
              >
                  <Colorpicker popup  picker="ChromePicker" />
              </Form.Item>

              <Form.Item label={t("channels.fields.ctaButtonSize")} name="ctaButtonSize">
                <Radio.Group defaultValue="large" buttonStyle="solid">
                    <Radio.Button value="large">Large (Full)</Radio.Button>
                    <Radio.Button value="small">Small</Radio.Button>
                </Radio.Group>
              </Form.Item>

              <Form.Item label={t("channels.fields.productImageSize")} name="productImageSize">
                <Radio.Group defaultValue="small" buttonStyle="solid">
                    <Radio.Button value="small">Small</Radio.Button>
                    <Radio.Button value="large">Large</Radio.Button>
                </Radio.Group>
              </Form.Item>

              <Form.Item label={t("channels.fields.productTheme")} name="productTheme">
                <Radio.Group defaultValue="light" buttonStyle="solid">
                    <Radio.Button value="light">Light</Radio.Button>
                    <Radio.Button value="dark">Dark</Radio.Button>
                </Radio.Group>
              </Form.Item>

              <Form.Item label={t("channels.fields.quickCart")} name="quickCart">
                <Radio.Group defaultValue={0} buttonStyle="solid">
                    <Radio.Button value={1}>Yes</Radio.Button>
                    <Radio.Button value={0}>No</Radio.Button>
                </Radio.Group>
              </Form.Item>

              <Form.Item label={t("channels.fields.cartRedirect")} name="cartRedirect">
                <Radio.Group defaultValue={0} buttonStyle="solid">
                    <Radio.Button value={1}>Yes</Radio.Button>
                    <Radio.Button value={0}>No</Radio.Button>
                </Radio.Group>
              </Form.Item>

              <Form.Item label={t("channels.fields.continueVideoAddtocart")} name="continueVideoAddtocart">
                <Radio.Group defaultValue={0} buttonStyle="solid">
                    <Radio.Button value={1}>Yes</Radio.Button>
                    <Radio.Button value={0}>No</Radio.Button>
                </Radio.Group>
              </Form.Item>

              
              <Form.Item label={t("channels.fields.forceMute")} name="forceMute">
                <Radio.Group defaultValue={0} buttonStyle="solid">
                    <Radio.Button value={1}>Yes</Radio.Button>
                    <Radio.Button value={0}>No</Radio.Button>
                </Radio.Group>
              </Form.Item>
              
          </Form>
      </Edit>
  );
};

// #4681f4
// border corner style