import { Table, useTable,
  Image, Button, Icons, Row, Col, Form, Input, Spin } from "@pankod/refine-antd";
import { useApiUrl, useNotification, OpenNotificationParams, useDataProvider } from "@pankod/refine-core";
import { useState, useEffect } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';

export const ShopifyProductsList: React.FC<{sfId: string, shop: string}> = ({ sfId, shop }) => {
  const [productId, setProductId] = useState("");
  const [syncing, setSyncing] = useState<any>({});
  const sfApiUrl = useApiUrl('sf');
  const dataProvider = useDataProvider();
  const [items, setItems] = useState<any>([]);
  const [page, setpage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const { setFilters, searchFormProps, tableProps } = useTable<any>({
    resource: `shopify/sf/${sfId}/products`,
    onSearch: (params: any) => {
      const { title } = params;
      setpage(1);
      setItems([]);
      setHasMore(true);
      const fl: any = [
        {
          field: 'title',
          operator: "contains",
          value: title,
        },
        {
          field: 'shop',
          operator: 'eq',
          value: shop,
        },
        {
          field: '_start',
          operator: 'eq',
          value: 0,
        },
        {
          field: '_end',
          operator: 'eq',
          value: 30,
        }
      ]
      return fl;
    },
    dataProviderName: 'sf',
    hasPagination: false,
    initialFilter: [
      {
        field: 'shop',
        operator: 'eq',
        value: shop,
      },
      {
        field: '_start',
        operator: 'eq',
        value: 0,
      },
      {
        field: '_end',
        operator: 'eq',
        value: 30,
      }
    ],
    initialPageSize: 30,
    queryOptions: {
          onSuccess: (newData: any) => {
            const ft: any = [...items, ...(newData.data ?? [])];
            if(newData.data.length < 30) {
              setHasMore(false);
            }
            setItems(ft);
          },
        }
  });


  const refresh = () => {
      setHasMore(true);
      setpage(1);
      setItems([]);
    setFilters([
      {
        field: 'shop',
        operator: 'eq',
        value: shop,
      },
      {
        field: '_start',
        operator: 'eq',
        value: 0,
      },
      {
        field: '_end',
        operator: 'eq',
        value: 30,
      },
      {
        field: 'r',
        operator: 'eq',
        value: Math.random(),
      },
    ]);
  };

  const handleFetch = () => {
    if(!items?.[page*30 - 1]) {
      setHasMore(false);
      return;
    }
    const _lastId = items?.[page*30-1]?.['cursor'];
    setpage(page+1);
    setFilters([
      {
        field: '_lastId',
        operator: 'eq',
        value: _lastId,
      },
      {
        field: 'shop',
        operator: 'eq',
        value: shop,
      },
      {
        field: '_start',
        operator: 'eq',
        value: page*30,
      },
      {
        field: '_end',
        operator: 'eq',
        value: (page+1)*30,
      }
    ]);
  };
  const { open } = useNotification();
    useEffect(() => {
    console.log('syncing', syncing);
  }, [syncing])

const SyncSfProduct = async (id: any) => {
  let newsyncing: any = syncing || {};
  newsyncing[id] = 1;
  setSyncing(newsyncing);
  setProductId(id);
  const sfDp = dataProvider('sf');
  console.log('product id', id);
  if(sfDp) {
    const res: any = await sfDp.create({
      resource: `storefronts/${sfId}/products/shopify`,
      variables: {
        shopifyProductId: id,
      },
    });
    const finalsyncing = {
      ...newsyncing,
    }
    finalsyncing[id] = false;
    setSyncing(finalsyncing);
    const notifparams: OpenNotificationParams = {
      type: 'success',
      message: "Sync Successful",
      description: `${res.data.platformProduct?.title} Synced`,
      key: `product-success-${id}`,
    }
    open?.(notifparams);
    console.log('sync done', res);
  }
};



  return (
    <Row gutter={[16, 16]}>
            <Col xs={24}>
                <Form layout="inline" {...searchFormProps}>
                    <Form.Item name="title">
                        <Input 
                            placeholder="Search Title or Name"
                            prefix={<Icons.SearchOutlined />}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" type="primary">
                            Filter
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
            <Col xs={24}>

            <InfiniteScroll
  dataLength={items.length} //This is important field to render the next data
  next={handleFetch}
  hasMore={hasMore}
  loader={<h4>Loading...</h4>}
  endMessage={
    <p style={{ textAlign: 'center' }}>
      <b>That's all!</b>
    </p>
  }
  refreshFunction={refresh}
  pullDownToRefresh
  pullDownToRefreshThreshold={50}
  pullDownToRefreshContent={
    <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
  }
  releaseToRefreshContent={
    <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
  }
>
     
          {items.length>0 && <Table {...tableProps} dataSource={items}>
              <Table.Column dataIndex="featuredImage" render={
                (value: any) => <Image src={value?.url} height={96} width={96} />
              } title="Image" />
              <Table.Column dataIndex="title" title="Title" />
              <Table.Column dataIndex="onlineStorePreviewUrl"
              render={(value: any, record: any) => (
                <div key={`record-actions-sf-shopify-product-${record?.id}`}>
                {syncing?.[record?.id] && <Button><Spin indicator={<Icons.LoadingOutlined />} /></Button>}
                {!syncing?.[record?.id] && <Button onClick={async(e) => SyncSfProduct(record?.id)} title="Import">{!record.sync ? 'Import' : 'Re-sync'}</Button>}

                <Button href={record?.onlineStoreUrl || value} target="_blank" title="Preview" style={{ marginLeft: '10px'}}><Icons.EyeOutlined /></Button>
                </div>
              )} title="Actions" />
          </Table>}
          </InfiniteScroll>
      </Col>
        </Row>
  );
};

interface IProduct {
  id: string;
  title: string;
  featuredImage: object;
  priceRangeV2: object;
  onlineStorePreviewUrl: object;
  options: object;
  totalVariants: number;
  hasOnlyDefaultVariant: boolean;
  hasOutOfStockVariants: boolean;
  images: object;
  status: string;
}

interface IShopifyProduct {
  id: string,
  platform: string;
  platformId: string;
  platformProduct: object;
}
