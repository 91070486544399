import { useTranslate, useApiUrl } from "@pankod/refine-core";

import { useEffect, useState } from "react";
// import { GetImageInfo } from "utils/getImageInfo";
// import {Helmet} from "react-helmet";
// import { IPhoto } from "interfaces";
import Evaporate from 'evaporate';
import sparkMD5 from 'spark-md5';
// import sha256 from 'crypto-js/sha256'
import { sha256 } from "js-sha256";
import { v4 as uuidv4 } from "uuid";

import {
    Edit,
    Drawer,
    DrawerProps,
    Form,
    FormProps,
    Input,
    // Space,
    ButtonProps,
    // Avatar,
    // Typography,
    // Upload,
    // Grid,
    // getValueFromEvent,
    Row,
    Col,
    Collapse,
    Radio,
    AutoComplete,
    Image,
    getValueFromEvent,
    Upload,
    Space,
    Avatar,
    Icons,
    Typography,
} from "@pankod/refine-antd";

import slugify from "slugify";
import sanitize from "sanitize-filename";
import { SelectPhotoProducts } from "components/products";

const { Panel } = Collapse;
const { Text } = Typography;

// const { Text } = Typography;

type EditPhotoProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
    onFinish: any;
};

export const EditPhoto: React.FC<EditPhotoProps> = ({
    drawerProps,
    formProps,
    saveButtonProps,
    onFinish,
}) => {
    const t = useTranslate();
    // const apiUrl = useApiUrl();
    const { form } = formProps;
    const [shoppableType, setShoppableType] = useState('');
    const [photoType, setPhotoType] = useState('short');
    const [photoId, setPhotoId] = useState('');

  const initialImageInfo: any = {

  };
    const [photoUrl, setPhotoUrl] = useState('');
    const apiUrl = useApiUrl('auth');
    const uploader = Evaporate.create({
        signerUrl: `${apiUrl}/signv4_upload`,
        aws_key: 'AKIAXM26U6RWGEOTWZTX',
        awsRegion: 'ap-south-1',
        awsSignatureVersion: "4",
        bucket: 'allstudio-images',
        cloudfront: true,
        computeContentMd5: true,
        cryptoMd5Method: (d) => btoa(sparkMD5.ArrayBuffer.hash(d, true)),
        cryptoHexEncodedHash256: (d) => sha256(d?.toString() || '').toString(),
      });

    const ctaOptions = [
        { value: 'Shop Now' },
        { value: 'Order Now' },
        { value: 'Book Now' },
        { value: 'Apply Now' },
        { value: 'Play Now' },
        { value: 'Download' },
        { value: 'Add to Cart' },
        { value: 'Contact Us' },
        { value: 'Get Offer' },
        { value: 'Get Quote' },
        { value: 'Get Showtimes' },
        { value: 'Learn More' },
        { value: 'See More' },
        { value: 'See Menu' },
        { value: 'Read More' },
        { value: 'Watch' },
        { value: 'Donate' },
        { value: 'Follow' },
        { value: 'RSVP' },
        { value: 'Show Interest' },
        { value: 'Schedule a Call' },
    ];
    // const breakpoint = Grid.useBreakpoint();


    // const breakpoint = Grid.useBreakpoint();

    useEffect(() => {
        if(formProps?.initialValues?.id) {
            setPhotoId(formProps?.initialValues?.id);
            setPhotoType(formProps?.initialValues?.type || 'short');
            // if(formProps.initialValues.isProcessed) {
            //     if(photoType === 'short')
            //     setPhotoUrl(formProps.initialValues.processedUrls['576p']);
            //     else {
            //         setPhotoUrl(formProps.initialValues.processedUrls['op']);
            //     }
            // } else {
            //     setPhotoUrl(formProps.initialValues.url);
            // }
            setPhotoUrl(formProps.initialValues.finalUrls['576']);
            console.log(photoUrl);
            console.log(photoType);
            if(!form?.getFieldValue('type')) {
                form?.setFieldsValue({
                    type: photoType,
                });
            }
        }
        setTimeout(() => {
            setShoppableType(form?.getFieldValue('shoppableType'));
        }, 500);

        if(!shoppableType) {
            // form?.setFieldsValue({
            //     shoppableType: (photoType === 'banner') ? 'link' : 'cta',
            // })
        }

    }, [formProps, photoId, form, photoUrl, shoppableType, photoType]);



const [imageInfo, setImageInfo] = useState(initialImageInfo);

  const GetImageInfo = (file: File) => new Promise((resolve, reject) => {
    if(imageInfo?.file === file) {
        console.log('resolving from state');
        resolve(imageInfo);
    }
    let img = document.createElement('img');
    

    img.onload = () => {
        setImageInfo({
            file,
            width: img.width,
            height: img.height,
        })    
        resolve(imageInfo);         
     };

    img.src = URL.createObjectURL(file);
});



    const updateProductIds = (values: any) => {
       console.log('updating selected products', values);
       const shoppableData = form?.getFieldValue('shoppableData');
       shoppableData.productIds = values; 
        form?.setFieldsValue({
            shoppableData,
        });
    };

    const editPhoto = async (values: any) => {
        delete values.file;
        const response: any = await onFinish(values);
        const photoId = response.data.data.photoId;
        console.log(photoId)
    };

    return (
        <Drawer
            {...drawerProps}
            width={"100%"}
            bodyStyle={{ padding: 0 }}
            zIndex={1001}
            key={`edit-photo-${formProps?.initialValues?.id}`}
        >
            <div style={{maxWidth: '1000px', margin: '0 auto'}}>
            <Row
                  gutter={[16, 16]}
                  style={{ background: "#fff", padding: "16px 0px" }}
              >
      
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 15, offset: 1 }} md={{ span: 16, offset: 1 }}>
                <Edit
                        saveButtonProps={saveButtonProps}
                        pageHeaderProps={{ extra: null }}
                        resource="photos"
                        breadcrumb={false}
                        goBack={false}
                    >
                        <Form {...formProps} onFinish={editPhoto} layout="vertical">

                     <Form.Item name="type" hidden>
                        <Input />
                    </Form.Item>

                    <Form.Item name="mimeType" hidden>
                        <Input />
                    </Form.Item>

                    <Form.Item name="width" hidden>
                        <Input />
                    </Form.Item>

                    <Form.Item name="height" hidden>
                        <Input />
                    </Form.Item>

                    <Form.Item name="url" hidden>
                        <Input />
                    </Form.Item>

                    <Form.Item name="s3Key" hidden>
                        <Input />
                    </Form.Item>

                            <Form.Item
                                label={t("photos.fields.title")}
                                name="title"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>


                            <Collapse defaultActiveKey={['1']}>

                            <Panel header="Change Image" key="2">
                                    <Image width={200} src={formProps?.initialValues?.finalUrls['576']} />
                                    <Form.Item label={t("photos.fields.poster.label")}>
                        <Form.Item
                            name="file"
                            valuePropName="list"
                            getValueFromEvent={getValueFromEvent}
                            noStyle
                            rules={[
                                {
                                    validator: async (_, value) => {
                                        if (!value || !value[0] || !value[0]?.originFileObj) return Promise.resolve();
                                        // console.log('validating photo');
                                        // console.log(value);
                                        // const file = value[0].originFileObj;
                                        // const vinfo: any = await getPhotoInfo(file);
                                            return Promise.resolve();
                                        
                                        // return Promise.reject(
                                        //     new Error("Photo duration should be less than 2 minutes."),
                                        // );
                                    },
                                },
                            ]}
                        >
                            <Upload.Dragger
                                name="file"
                                onChange={async (info) => {
                                    const newFileName = (info.fileList.length) ? info.file.name : '';
                                    if(newFileName) {
                                        const file: any = info.file.originFileObj;
                                        const vinfo: any = await GetImageInfo(file);
                                        form?.setFieldsValue({
                                            width: vinfo?.width,
                                            height: vinfo?.height,
                                        });
                                    }
                                    // const splitFile = newFileName.split(".");
                                    // splitFile.pop();
                                    return Promise.resolve();
                                }}
                                beforeUpload={async (file) => {
                                    // const vinfo: any = await getImageInfo(file);
                                    // if(vinfo?.duration > 120) {
                                    //     return false;
                                    // }
                                    return true;
                                }}
                                customRequest={async (req) => {
                                    console.log(`custom request`);
                                    console.log(req);
                                    const finalFile: any = req.file;
                                    const finaluploader = await uploader;
                                    const fileType = finalFile.type;
                                    const fileName = finalFile.name;
                                    const splitFile = fileName.split(".");
                                    const fileExt = splitFile.pop();
                                    const fileNameWExt = splitFile.join(".");
                                    const typeExt = `${photoType !== 'short' ? `_${photoType}` : ''}`;
                          
                                    const key = `${process.env.REACT_APP_ENV}/${slugify(sanitize(fileNameWExt).toLowerCase())}_${uuidv4()}${typeExt}.${fileExt}`;

                                    const awskey = await finaluploader.add({
                                        name: `allstudio${photoType !== 'short' ? `-${photoType}` : ''}/${key}`,
                                        file: finalFile,
                                        notSignedHeadersAtInitiate: {
                                            'Cache-Control': `max-age=${365*24*60*60}`,
                                            'Content-Type': fileType,
                                        },
                                        xAmzHeadersAtInitiate: {
                                            'x-amz-acl': 'public-read'
                                        },
                                        complete: (xhr, awsObjectKey, stats) => {
                                            console.log(`complete ${awsObjectKey} ${JSON.stringify(stats)}`);
                                            req.onSuccess?.({
                                                s3Key: awsObjectKey,
                                            }, xhr);
                                        },
                                        progress: (p) => {
                                            console.log(`progress ${p}`);
                                            req.onProgress?.({ percent: p*100 });
                                        }
                                    });
                                    console.log('calling complete again');
          
                                    form?.setFieldsValue({
                                        url: `https://cdnv2.allstudio.live/${key}`,
                                        s3Key: awskey,
                                        mimeType: fileType,
                                    });
                                    console.log(form?.getFieldsValue());
                                    req.onSuccess?.(awskey);

                                    return awskey;
                                }}
                                listType="picture"
                                maxCount={1}
                                accept=".jpg,.png,.webp"
                            >
                                <Space direction="vertical" size={2}>
                                    <Avatar
                                        style={{
                                            width: "36px",
                                            height: "36px",
                                        }}
                                        icon={<Icons.UploadOutlined  style={{
                                            fontSize: 24,
                                            padding: 4,
                                        }} />}
                                        alt="Upload Photo"
                                    />
                                    <Text
                                        style={{
                                            fontWeight: 800,
                                            fontSize: "16px",
                                            marginTop: "16px",
                                        }}
                                    >
                                        {t(
                                            "photos.fields.poster.description",
                                        )}
                                    </Text>
                                    <ul style={{
                                        textAlign: 'left',
                                    }}>
                                    <li style={{ fontSize: "12px" }}>
                                        {t("photos.fields.poster.validationSize")}
                                    </li>
                                    <li style={{ fontSize: "12px" }}>
                                        {t("photos.fields.poster.validationFormat")}
                                    </li>
                                    {/* <li style={{ fontSize: "12px" }}>
                                        {t("photos.fields.poster.validationAspectRatio")}
                                    </li> */}
                                    </ul>
                                </Space>
                            </Upload.Dragger>
                        </Form.Item>
                    </Form.Item>
                                </Panel>
                                <Panel header="Photo Actions" key="1">
                                <Form.Item
                                label={t("photos.fields.shoppableType")}
                                name="shoppableType"
                                >
                                <Radio.Group onChange={(e) => {
                                    setShoppableType(e.target.value);
                                }} 
                                // defaultValue={form?.getFieldValue('type') === 'short' ? 'cta' : 'link'} 
                                buttonStyle="solid">
                                    { photoType === 'short' ? (<>
                                    <Radio.Button value="cta">Link Button (CTA)</Radio.Button>
                                    <Radio.Button value="products">Products</Radio.Button>
                                    <Radio.Button value="addtocart">Add to Cart</Radio.Button></>) : (
                                        <Radio.Button value="link">Link</Radio.Button>
                                    )}
                                    {/* <Radio.Button value="c">Beijing</Radio.Button>
                                    <Radio.Button value="d">Chengdu</Radio.Button> */}
                                </Radio.Group>
                                </Form.Item>

                                
                               
                                {shoppableType ===  'cta' && (
                                <div>
                                <Form.Item
                                label={t("photos.fields.ctaType")}
                                name={['shoppableData', 'ctaType']}
                                rules={[
                                    {
                                        min: 2,
                                        message: "Max 2 characters allowed"
                                    },
                                    {
                                        max: 20,
                                        message: "Max 20 characters allowed"
                                    }
                                ]}
                                >
                                <AutoComplete
                                    // style={{ width: 200 }}
                                    options={ctaOptions}
                                    placeholder="Choose Button Text"
                                    filterOption={(inputValue, option) =>
                                        option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                />
                                </Form.Item>
                                <Form.Item
                                label={t("photos.fields.ctaLink")}
                                name={['shoppableData', 'ctaLink']}
                                rules={[
                                    {
                                        type: "url",
                                        message: "This field must be a valid url."
                                    }
                                ]}
                                >
                                <Input
                                    placeholder="https://yourlink.com"
                                />
                                </Form.Item>
                                </div>)}

                                {shoppableType ===  'products' && (
                                <div>

                                 <Form.Item 
                                 name={['shoppableData', 'productIds']}
                                 initialValue={[]}
                                >
                                <SelectPhotoProducts productIds={form?.getFieldValue(['shoppableData', 'productIds'])} onUpdate={updateProductIds} photoId={photoId} />
                                </Form.Item>
                                </div>)}   

                                {shoppableType ===  'addtocart' && (
                                <div>
                                <Form.Item
                                initialValue="ADD TO CART"
                                label={t("photos.fields.ctaType")}
                                name={['shoppableData', 'ctaType']}
                                rules={[
                                    {
                                        min: 2,
                                        message: "Max 2 characters allowed"
                                    },
                                    {
                                        max: 20,
                                        message: "Max 20 characters allowed"
                                    }
                                ]}
                                >
                                <AutoComplete
                                    // style={{ width: 200 }}
                                    options={ctaOptions}
                                    placeholder="Choose Button Text"
                                    filterOption={(inputValue, option) =>
                                        option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                />
                                </Form.Item>

                                <Form.Item
                                label={t("photos.fields.platform")}
                                name={['shoppableData', 'platform']}
                                initialValue="shopify"
                                hidden
                                >
                                <Input />
                                </Form.Item>

                                <Form.Item
                                label={t("photos.fields.productId")}
                                name={['shoppableData', 'productId']}
                                >
                                <Input />
                                </Form.Item>
                                </div>)}



                                {shoppableType ===  'link' && (
                                <div>
      
                                <Form.Item
                                label={t("photos.fields.ctaLink")}
                                name={['shoppableData', 'ctaLink']}
                                rules={[
                                    {
                                        type: "url",
                                        message: "This field must be a valid url."
                                    }
                                ]}
                                >
                                <Input
                                    placeholder="https://yourlink.com"
                                />
                                </Form.Item>


                                <Form.Item
                                label={t("photos.fields.newTab")}
                                name={['shoppableData', 'newTab']}
                                >
                                <Radio.Group 
                                defaultValue="0"
                                buttonStyle="solid">
                                 <Radio.Button value={1}>Yes</Radio.Button>
                                 <Radio.Button value={0}>No</Radio.Button>
                                 </Radio.Group>
                                </Form.Item>

                                </div>)}

                                </Panel>

                            </Collapse>



                            {/* <Form.Item
                                label={t("photos.fields.description")}
                                name="description"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input.TextArea rows={6} />
                            </Form.Item> */}
                        </Form>
                        </Edit>
                </Col>

                <Col xs={{ span: 12, offset: 6 }} sm={{ span: 6, offset: 1 }} md={{ span: 6, offset: 1 }}>

{photoId && 
<div>
   <img alt="" src={photoUrl} width="100%" />
    <b style={{marginTop: '8px' }}>photoId: {photoId}</b>
</div>
}
</Col>
            </Row>
            </div>
     
        </Drawer>
    );

};

