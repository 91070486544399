import {
  useTranslate,
} from "@pankod/refine-core";
import cubejs from '@cubejs-client/core';
import { QueryRenderer } from "@cubejs-client/react";
import { Col, Card, Spin, Row, Statistic, useTable, Table, Typography } from "@pankod/refine-antd";
import { Chart, Axis, Tooltip, Geom, Coord, Legend, Label } from "bizcharts";
import moment from "moment";
import { IPlaylist } from "interfaces";
const { Text } = Typography;
// import numeral from "numeral";

// const numberFormatter = (item: any) => numeral(item).format("0,0");

const dateFormatter = (item: any) => moment(item).format("MMM YY");

const colors = ["#7DB3FF", "#49457B", "#FF7C78"];
let apiTokenPromise;

const renderChart = (Component: any, options = {}) => ({ resultSet, error } : any) =>
  (resultSet && <Component resultSet={resultSet} {...options} />) ||
  (error && error.toString()) || <div style={{ textAlign: "center", paddingTop: 30 }}><Spin /> </div>;


const numberRender = ({ resultSet }: any) => (
  <Row
    justify="center"
    align="middle"
    style={{
      height: "100%"
    }}
  >
    <Col>
      {resultSet.seriesNames().map((s: any) => (
        <Statistic value={resultSet.totalRow()[s.key]} />
      ))}
    </Col>
  </Row>
);


export const PlaylistPerformance: React.FC<any> = ({
  dateRange,
  channelId,
}) => {
  const t = useTranslate();
  const apiKey: any = process.env.REACT_APP_CUBEJS_API_KEY;
  const apiUrl: any = process.env.REACT_APP_CUBEJS_API_URL;
  const cubejsApi = cubejs(
    apiKey,
    { apiUrl }
  );

  let env: string = process.env.REACT_APP_ENV || 'prod';
  if(env === 'prod' || env === '') {
    env = 'prod';
  }

  const { tableProps, tableQueryResult } = useTable<IPlaylist>({
    resource: `channels/${channelId}/playlists`,
  });

  const defaultFilters: any = [
    {
      member: `Events.env`,
      operator: `equals`,
      values: [env]
    },
    {
      member: `Events.appId`,
      operator: `equals`,
      values: ['allstudio']
    },
    {
      member: `Events.channelId`,
      operator: `equals`,
      values: [channelId]
    },
  ];

  
  return (
      <>

<Table {...tableProps} rowKey="id">
              <Table.Column dataIndex="name" render={
                (value, record: any) => <Text>{record?.name}</Text>
              } title="Name" />
              <Table.Column dataIndex="name"
              render={(value, record: any) => (
                <>
                </>
              )} title="Views" /> </Table>
      </>
  );
};